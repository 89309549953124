import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


const Hero = ({ mainImageData }) => {
  return (
    <div className="flex flex-col justify-center">
      <GatsbyImage
        image={mainImageData}
        className="object-contain w-full"
        alt="Lemonberry Labs"
      />
      <div className="flex justify-center">Coming soon</div>
    </div>
  )
}


const IndexPage = ({ data }) => {

  return (
    <Layout>
      <Seo title={data.site.siteMetadata.title} />
      <div>
        <Hero
          mainImageData={data.logo.childImageSharp.gatsbyImageData}
        />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query HomePageQuery {
        site {
            siteMetadata {
                title
            }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                gatsbyImageData(height: 800, layout: CONSTRAINED)
            }
        }
    }
`
